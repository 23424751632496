<template>
  <div>
    <action-header
        :is-loading="!movement"
        :actions-model="headerModel"
        :page="page"
    />


  <div class="q-px-xs q-py-md">
    <form-builder :schema="schema" />
  </div>

    <div class="q-px-xs q-py-md">
      <barcode-input
          :settings="{catchAll: false}"
          :out-focused="isFocused"
          :has-max-width="false"
          :disabled="isLoading"
          @barcode="handleBarcode"
      />
    </div>

  <q-card v-if="movement && movement.id" class="q-ma-sm">
    <q-card-section class="q-py-xs q-px-none">
      <div :class="`q-pa-sm row`">
        <q-legend
            :label="$t('Shipments')"
        />
        <q-space/>
          <q-btn
            color="dark"
            class="q-mr-sm"
            :label="$t('Filter')"
            no-caps
            @click="toggleFilters"
          />

          <q-btn
            color="danger"
            text-color="white"
            class="q-mr-sm"
            :disable="selectedItems.length === 0"
            no-caps
            :label="$t('Delete') + `${selectedItems.length > 0 ? ` (${selectedItems.length})` : ''}`"
            @click="handleDelete"
          />

      </div>
    </q-card-section>

    <q-card-section>
        <filter-collapse
          :is-open="isOpenFilter"
          :options="{
            defaultFilter: filters,
            fields: activatedFields,
            values: {
              states: deliveryRequestsStatuses
            },
            style: {
              noGroups: true,
          },
        }"
                @submit="handleFiltersSubmit"
                @close="toggleFilters"
        />
    </q-card-section>

    <q-table
        style="height: calc(100vh - 250px);"
        row-key="id"
        :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="isLoading"
        :filter="filter"
        selection="multiple"
        v-model:selected="selectedItems"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
    >

      <template v-slot:header-selection="props">
        <q-checkbox
          size="xl"
          :model-value="selectedItems.length > 0"
          color="amber-6"
          @click="handleSelectAll(props)"
        />
      </template>

      <template
          v-slot:body="props"
          class="clickable"
      >
        <delivery-request-row-table
            :data="props"
            :row="props.row"
            :items="selectedItems"
            :column="props.column"
            @check="handleCheck"
            @dbclick="onRowClick"
        />
      </template>
    </q-table>
  </q-card>
  </div>
</template>

<script>

// Vuex
import { mapGetters, mapMutations, mapActions } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader'
import DeliveryRequestRowTable from '../../components/delivery-request-row-table/DeliveryRequestRowTable.vue'
import BarcodeInput from '../../components/barcode-input/BarcodeInput'

// Helpers
import { buildQuery } from '../../utils/query-utils'
import FilterCollapse from "./../../components/filters/FilterCollapse.vue";
import deliveryRequestStatusMatrix from "./../../config/DeliveryRequestMatrix";

export default {
  name: 'CartonForwarding',
  components: {
      FilterCollapse,
    ActionHeader,
    DeliveryRequestRowTable,
    BarcodeInput
  },
  data () {
    return {
      items: [],
      selectedItems: [],
      isFocused: false,
      isOpenFilter: false,
      filters: null,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      isLoading: false,
      filter: '',
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Customer'),
          name: 'customer',
          align: 'left'
        },
        {
          label: this.$t('Sender'),
          name: 'sender',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Shipping'),
          name: 'shipping',
          align: 'left'
        },
        {
          label: '',
          name: 'actions',
          align: 'left'
        }
      ],
      activatedFields: [
          'id',
          'created.from',
          'created.to',
          'createdByDocument',
          'state',
      ],
      deliveryRequestsStatuses: deliveryRequestStatusMatrix
    }
  },
  mounted() {
    if (this.$route.params.id) {
      const pagination = {
        per_page: 25,
        page: 1,
        filter: [],
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      this.onRequest({ pagination })
    }
      this.loadMovement(this.$route.params.id)

  },
  computed: {
    ...mapGetters([
      'movement',
      'appOptions'
    ]),
    page () {
      return {
        id: this.movement && this.movement.id,
        name:  this.$t('Carton Forwarding')
      }
    },
    customValueDocument () {
      if(this.movement && this.movement.created) {
        return `${this.movement.id} from ${this.$moment(this.movement.created.date).format(this.appOptions.formats.fullDate)}`
      }
    },
    headerModel () {
      if (!this.movement) {
        return []
      }


      const newState = { id: 'new', title: this.$t('New'), transitions: ['confirmed', 'checking', 'complete', 'deleted', 'cancelled', 'locked'], color: 'info' }
      const checkingState = { id: 'checking', title: this.$t('Checking'), transitions: ['new', 'confirmed', 'complete', 'deleted', 'cancelled', 'locked'], color: 'amber' }
      const confirmedState = { id: 'confirmed', title: this.$t('Confirmed'), transitions: ['new', 'checking', 'complete', 'deleted', 'cancelled', 'locked'], color: 'teal' }
      const closedState = { id: 'complete', title: this.$t('Complete'), transitions: ['new', 'confirmed', 'checking', 'deleted', 'cancelled', 'locked'], color: 'success' }
      const cancelledState = { id: 'cancelled', title: this.$t('Cancelled'), transitions: ['new', 'confirmed', 'checking', 'complete', 'deleted', 'locked'], color: 'negative text-white' }
      const lockedState = { id: 'locked', title: this.$t('Locked'), transitions: ['new', 'confirmed', 'checking', 'complete', 'deleted', 'cancelled'], color: 'warning' }
      const deleted = { id: 'deleted', title: this.$t('Deleted'), transitions: ['new', 'confirmed', 'checking', 'complete', 'cancelled', 'locked'], color: 'danger' }

      const states = [
        { buttons: [newState, checkingState, confirmedState, closedState, cancelledState, lockedState, deleted] }
      ]

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: states[0].buttons[0].color,
        editableFields: false,
        onClick: (state) => {
          this.movement.state = state
          return this.$service.movement.save({state: state}, this.movement.id)
        },
        value: states[0].buttons[0],
        options: states
      }

      const dropdown = {
        section: 'Dropdown',
        className: 'col-12 col-sm row justify-end q-pb-sm',
        options: [
          option
        ]
      }

      if (this.movement && this.movement.state !== option.value.id) {
        states.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === this.movement.state
          })

          if (status) {
            option.color = group.color || status.color
            option.value = status
          }

          return status
        })
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              type: 'text',
              value: this.$t('Carton Forwarding ID') + ': ' + this.movement.id
            }
          ]
        },
        dropdown
      ]
    },
    schema () {
      return {
        isLoading: !this.movement,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Document'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'comment',
                disabled: true,
                value: this.customValueDocument,
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Comment'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'comment',
                value: this.movement && this.movement.comment,
                onChange: (comment) => {
                    this.movement.comment = comment
                    return this.$service.movement.save({comment: comment}, this.movement.id)
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'sender',
                disabled: true,
                value: this.movement && this.movement._embedded && this.movement._embedded.sender && this.movement._embedded.sender.name,
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Items in document'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'itemsInDocument',
                disabled: true,
                value: this.movement && this.movement.deliveryRequests && this.movement.deliveryRequests.count,
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('First Task State'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'state',
                disabled: true,
                value: this.movement && this.movement.taskState ,
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Not shipped delivery requests'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'notShipped',
                disabled: true,
                value: this.movement && this.movement.deliveryRequests && this.movement.deliveryRequests.notShipped,
              },
            ]
          }
        ]
      }
    }
  },
  methods : {
    ...mapActions([
      'loadMovement'
    ]),
    ...mapMutations([
        'setDeliveryRequest',
        'addErrorNotification'
    ]),
    handleCheck (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.selectedItems = [item, ...this.selectedItems]
      }
    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    handleFiltersSubmit (filter) {
        this.isOpenFilter = false
        this.filters = filter
        return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilters () {
        this.isOpenFilter = !this.isOpenFilter
    },
    handleBack () {

      this.$router.back()
    },
    onRowClick (item) {
      this.setDeliveryRequest(item)
      this.$router.push('/outbound/shipments/entity/' + item.id)
    },
    handleBarcode (barcode) {
      if (barcode.type === 'DS/DR') {
        // 15179*OA30143086*1
        let data = barcode.raw
          .split('*')
        let result
        if(data.length > 1) {
          data = barcode.raw.split('/')
          result = data[data.length - 1]
        } else {
          result = barcode.value
        }

        const query = {
          search: result
        }
        return this.$service.deliveryRequest.getAll(query)
          .then(({items}) => {
            if(items.length === 0) {
              this.addErrorNotification(`No shipment found with barcode ${result}`)
              return
            }
            let dr = items[0]
            const query = {
              action: 'add-requests'
            }
            this.$service.movement.save({deliveryRequests: [dr.id]},this.movement.id, undefined, query)
              .then(()=> {
                this.onRequest()
              })
          })

      } else {
        const query = {
          action: 'add-requests'
        }
        this.$service.movement.save({deliveryRequests: [barcode.raw]},this.movement.id, undefined, query)
          .then(()=> {
            this.onRequest()
          })
      }
    },
    async handleDelete () {
      for (const selectedItem of this.selectedItems) {

          await this.removeItems(selectedItem);
      }

    },
    removeItems (dr) {
      const query = {
        action: 'remove-request'
      }
      this.$service.movement.save({deliveryRequests: dr.id},this.movement.id, undefined, query)
        .then(()=> {
          this.onRequest()
        })
    },
    onRequest (data = {}) {
      this.isLoading = true
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      query.action = 'delivery-requests'
      query.criteria = {
        id: this.$route.params.id
      }
      if ( this.filters ) {
        query.filter=this.filters
      }
      return this.$service.movement.getAll(query)
          .then(({ page, totalItems, items }) => {
            this.items = items
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
  }
}
</script>